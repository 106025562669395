import clsx from 'clsx'
import Link from 'next/link'
import { MaterialSymbolsArrowForwardIosRounded } from '../data/svgIcons/all-svg-icons'
import { useState, useEffect } from 'react'
import { LanguageContext } from '../hook/context'
import { useContext } from 'react'

const Features = () => {
  const [showAll, setShowAll] = useState(false)
  const [showMoreDiv, setShowMoreDiv] = useState(false)
  const { t } = useContext(LanguageContext)
  const list = t.features.featuresList

  const handleShowMore = () => {
    setShowMoreDiv(false)
    setShowAll(true)
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowAll(false)
      setShowMoreDiv(true)
    } else {
      setShowAll(true)
      setShowMoreDiv(false)
    }
  }, [])

  return (
    <div>
      <div className='mt-24 flex flex-col items-center justify-center text-center'>
        <h2 className='title '>{t.features.title}</h2>
        {t.features.description}
      </div>

      <div className='my-10 relative'>
        <div
          className={clsx(
            showAll ? true : '[&>*:nth-child(1n+4)]:hidden',
            'relative grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1justify-between ',
          )}
        >
          {list.map((feature: any) => (
            <div
              key={feature.id}
              className={clsx(
                'relative group flex flex-col   justify-start items-start mx-1 mb-2 rounded-lg',
                'min-h-[225px] overflow-hidden  p-5 pt-0',
              )}
            >
              <div className='absolute -z-30 h-full w-full blur-xl bottom-1/2 left-1/2 bg-gray-700 transition-all duration-500 group-hover:-left-1/2 group-hover:-bottom-1/2'></div>
              <div className='absolute inset-[2px] -z-20 rounded-lg bg-[#101010]'></div>
              <div
                key={feature.description.toString()}
                className='mt-5 inline-flex bg-orange-500/20 space-x-2 p-2 rounded-lg hover:shadow-md  hover:scale-105 transition-all shadow-black/50'
              >
                {feature.icon}
              </div>
              <p className='absolute right-0 -bottom-5 -z-10 select-none text-9xl font-extrabold text-neutral-500 opacity-5  transition-all'>
                {feature.id}
              </p>
              <h3 className='mt-4 text-lg sm:text-2xl font-bold text-neutral-200'>
                {feature.title}
              </h3>
              <p className='mt-2 text-base text-neutral-400'>
                {feature.description}
              </p>
              {feature.link.length > 0 && (
                <Link
                  href={feature.link}
                  className='ml-auto items-center mt-3  bottom-3 right-4 z-10 rounded-lg px-3 py-1 text-gray-400 group-hover:text-blue-400 group-hover:font-bold transition-all hover:shadow-blue-400 hover:drop-shadow-lg'
                >
                  {t.buttons.readMore}{' '}
                  <MaterialSymbolsArrowForwardIosRounded className='inline-block ml-1' />
                </Link>
              )}
            </div>
          ))}
        </div>
        <div className='md:hidden'>
          {showMoreDiv && (
            <div className='w-full bg-gradient-to-t  from-primaryBlack z-10 h-56 absolute items-center bottom-0 flex justify-center'>
              <button
                className='bg-yellow-800 drop-shadow-lg py-3 px-4 rounded-full'
                onClick={handleShowMore}
              >
                {t.buttons.showMore}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Features
